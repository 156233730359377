import { Box, Button, Card, CardActions, CardContent } from '@mui/material';
import { useState } from 'react';
import { CompanyFragmentFragment } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import EditCompanyNoteDialog from './EditCompanyNoteDialog';

interface Props {
  company: CompanyFragmentFragment;
  className?: string;
}

const CompanyNoteCard = ({ company, className }: Props) => {
  const { _t } = useTranslationPrefix('companyNoteCard');
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <Card className={className}>
        <CardContent>
          <Box textAlign="center">
            {company.note || _t('noNote', 'Note is empty')}
          </Box>
        </CardContent>
        <CardActions>
          <Button
            id={`company-note-edit`}
            size="medium"
            variant="text"
            onClick={() => setDialogOpen(true)}
            sx={{ ml: 'auto' }}
          >
            {_t('editNote', 'Edit note')}
          </Button>
        </CardActions>
      </Card>
      <EditCompanyNoteDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        companyId={company.id}
        currentNote={company.note || ''}
      ></EditCompanyNoteDialog>
    </>
  );
};

export default CompanyNoteCard;
