import { Button } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import { EditCompanyQrSceneDocument } from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';
import { CompanyQrScene } from '../CompanyQRScenesPage';

interface FormData {
  additionalParams: string;
  ageFrom: string;
  ageTo: string;
}

interface Props {
  scene: CompanyQrScene;
  onClose: () => void;
  onSuccess?: () => void;
}

const EditCompanyQrSceneDialog = ({ scene, onClose, onSuccess }: Props) => {
  const { _t } = useTranslationPrefix('EditCompanyQrSceneDialog');

  const [editMutation] = useMutationWithSnackbar(
    'EditCompanyQrSceneDialog.editMutation',
    EditCompanyQrSceneDocument,
    {
      onCompleted: () => {
        onSuccess?.();
      },
    },
  );

  const handleSubmit = async (
    values: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    await editMutation({
      variables: {
        input: {
          id: scene.id,
          ageFrom: parseInt(values.ageFrom),
          ageTo: parseInt(values.ageTo),
          additionalParams: values.additionalParams,
        },
      },
    });
  };

  return (
    <BaseDialog
      open={!!scene}
      onClose={onClose}
      title={_t('title', 'Edit "{{name}}"', {
        name: scene.scene.name_t,
      })}
      disableEnforceFocus
    >
      <>
        <Formik<FormData>
          initialValues={{
            additionalParams: scene.additionalParams ?? '{"lang": "cs-CZ"}',
            ageFrom: `${scene.ageFrom ?? '0'}`,
            ageTo: `${scene.ageTo ?? '100'}`,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={getValidationSchema(_t)}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Flex flexDirection="column" gap={3}>
                <FormikTextField
                  fullWidth
                  name="ageFrom"
                  label={_t('ageFrom', 'Age from')}
                />
                <FormikTextField
                  fullWidth
                  name="ageTo"
                  label={_t('ageTo', 'Age to')}
                />
                <FormikTextField
                  fullWidth
                  name="additionalParams"
                  label={_t(
                    'dialog.additionalParams',
                    'Additional params (json)',
                  )}
                  multiline
                  minRows={3}
                  maxRows={8}
                />

                <Flex
                  justifyContent="center"
                  mt={1}
                  gap={3}
                  flexDirection={{ xs: 'column-reverse', md: 'row' }}
                >
                  <Button
                    id="create-playlist-folder-cancel"
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.cancel', 'Cancel')}
                  </Button>
                  <Button
                    id="create-playlist-folder-confirm"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.submit', 'Save')}
                  </Button>
                </Flex>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </>
    </BaseDialog>
  );
};

export default EditCompanyQrSceneDialog;

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    additionalParams: Yup.string()
      .required(
        _t('additionalParamsRequired', 'Additional params are required'),
      )
      .test('json', _t('additionalParamsInvalid', 'Invalid json'), (value) => {
        try {
          JSON.parse(value || '');
          return true;
        } catch (error) {
          return false;
        }
      }),
    ageFrom: Yup.number()
      .typeError(_t('ageFromRequired', 'Age from is required'))
      .required(_t('ageFromRequired', 'Age from is required'))
      .min(0, _t('ageFromMin', 'Age from cannot be negative'))
      .max(100, _t('ageFromRequired', 'Age from is too high')),
    ageTo: Yup.number()
      .typeError(_t('ageToRequired', 'Age to is required'))
      .required(_t('ageToRequired', 'Age to is required'))
      .min(0, _t('ageToMin', 'Age to cannot be negative'))
      .max(100, _t('ageToRequired', 'Age to is too high')),
  });
