import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Box, CardContent, CardProps } from '@mui/material';
import React from 'react';
import { BaseTypography } from '../../../../base/components/BaseTypography';
import { Flex } from '../../../../base/components/Flex';
import { IconButtonOutlined } from '../../../../base/components/IconButton';
import { SelectableCard } from '../../../../base/components/SelectableCard';
import { CompanyQrQuery } from '../../../../graphql/graphql-operations';

type Scene = CompanyQrQuery['companyQr']['qrScenes'][0]['scene'];

interface Props {
  id?: string;
  scene: Scene;
  onClick?: () => void;
  selected?: boolean;
  sx?: CardProps['sx'];
}

const QrSceneCard = ({
  id,
  scene,
  sx = {},
  onClick,
  selected,
  ...restCardProps
}: Props) => {
  return (
    <SelectableCard
      {...restCardProps}
      selected={selected}
      variant="outlined"
      elevation={0}
    >
      <CardContent onClick={onClick}>
        <Flex justifyContent="space-between" alignItems="center" gap={2}>
          <Flex
            alignItems="center"
            width="100%"
            overflow="hidden"
            height="6rem"
            m={-1}
          >
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                width: '19rem',
                height: '100%',
                flexShrink: 0,
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '10px',
                backgroundColor: 'primary.main',
                backgroundImage: scene.imageUrl
                  ? `url("${scene.imageUrl}")`
                  : undefined,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                ...sx,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  borderRadius: '6px',
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)',
                }}
              />

              <Flex
                px={2}
                mt={2}
                maxHeight="3.5rem"
                justifyContent="space-between"
                alignItems="center"
                position="relative"
              >
                <BaseTypography
                  variant="h5"
                  color={'white.main'}
                  sx={{
                    maxHeight: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {scene.name_t}
                </BaseTypography>
              </Flex>
            </Box>
          </Flex>
          <Flex alignItems="center" gap={2}>
            <IconButtonOutlined
              id={`add-scene-${scene.id}`}
              onClick={onClick}
              color="primary"
              disabled={!onClick}
            >
              <PlayArrowRoundedIcon />
            </IconButtonOutlined>
          </Flex>
        </Flex>
      </CardContent>
    </SelectableCard>
  );
};

export default React.memo(QrSceneCard);
