import { useState } from '@hookstate/core';
import { Button, DialogProps } from '@mui/material';
import { Formik } from 'formik';
import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikCheckBox from '../../../../base/components/form/FormikCheckBox';
import FormikFileSelect from '../../../../base/components/form/FormikFileSelect';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  DeleteCompanyQrSceneCertificateDocument,
  EditCompanyQrSceneCertificateDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { MAX_UPLOAD_FILE_SIZE } from '../../../../lib/constants';
import { CompanyQrScene } from '../CompanyQRScenesPage';

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';

export const SUPPORTED_FORMATS = ['application/json'];

interface CertificateTemplateForm {
  sendCcTo: string;
  sendToTrainees: boolean;
  attachment: File | null;
}

interface Props extends Omit<DialogProps, 'open'> {
  onClose: () => void;
  onSuccess: () => void;
  qrScene: CompanyQrScene;
}

const CompanyQrSceneCertificateTemplateDialog = ({
  onClose,
  onSuccess,
  qrScene,
  ...rest
}: Props) => {
  const deleteConfirmOpen = useState(false);
  const toggleDeleteConfirmDialog = () =>
    deleteConfirmOpen.set((prev) => !prev);

  const initialValues: CertificateTemplateForm = {
    sendCcTo: qrScene?.certSendCcTo ?? '',
    sendToTrainees: qrScene?.certSendToTrainees ?? true,
    attachment: null,
  };
  const { _t, t } = useTranslationPrefix(
    'CompanyQrSceneCertificateTemplateDialog',
  );

  const [editCompanyQrSceneCertificate] = useMutationWithSnackbar(
    'CompanyQrSceneCertificateTemplateDialog.edit',
    EditCompanyQrSceneCertificateDocument,
    {
      onCompleted: onSuccess,
    },
  );

  const [deleteCertificateTemplate] = useMutationWithSnackbar(
    'CompanyQrSceneCertificateTemplateDialog.delete',
    DeleteCompanyQrSceneCertificateDocument,
    {
      onCompleted: () => {
        toggleDeleteConfirmDialog();
        onSuccess();
      },
    },
  );

  const handleSubmit = (input: CertificateTemplateForm) => {
    const { attachment, sendCcTo, sendToTrainees } = input;
    return editCompanyQrSceneCertificate({
      variables: {
        file: attachment || null,
        input: {
          id: qrScene.id,
          certSendCcTo: sendCcTo,
          certSendToTrainees: sendToTrainees,
        },
      },
    });
  };

  const handleDeleteCertificateTemplate = () =>
    qrScene.id &&
    deleteCertificateTemplate({
      variables: { input: { id: qrScene.id } },
    });

  return (
    <BaseDialog
      {...rest}
      open={!!qrScene}
      onClose={onClose}
      title={_t(`title`, 'Update certificate')}
      disableEnforceFocus
    >
      <Formik<CertificateTemplateForm>
        initialValues={initialValues}
        validationSchema={getValidationSchema(t)}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ isSubmitting, isValid, values }) => (
          <FormikForm>
            <FormikFileSelect
              name="attachment"
              label={_t(`attachment`, 'Add attachment')}
              withValueLabel={_t('attachmentChangeLabel', 'Change attachment')}
              accept={SUPPORTED_FORMATS.join(', ')}
              disabled={isSubmitting}
              color="primary"
            />
            <FormikTextField
              name="sendCcTo"
              type="text"
              placeholder={_t('sendCcTo', 'Send e-mail CC to')}
              label={_t('sendCcToLabel', 'e-mail address list')}
            />
            <FormikCheckBox
              name="sendToTrainees"
              label={_t('sendToTrainees', 'Send to trainees')}
            />
            {qrScene.certFileLink && (
              <Flex justifyContent="center" marginTop={3}>
                <Button
                  id="qr-scene-remove-cert-edit-dialog-delete"
                  color="error"
                  variant="text"
                  startIcon={<DeleteRoundedIcon color="error" />}
                  onClick={toggleDeleteConfirmDialog}
                >
                  {_t('delete', 'Delete template')}
                </Button>
              </Flex>
            )}
            <Flex justifyContent="space-between">
              <Button
                id="certificateTemplate-edit-dialog-cancel"
                size="large"
                color="primary"
                disabled={isSubmitting}
                onClick={onClose}
              >
                {t('common.cancel', 'Cancel')}
              </Button>
              <Button
                id="certificateTemplate-edit-dialog-save"
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
              >
                {t('common.save', 'Save')}
              </Button>
            </Flex>
          </FormikForm>
        )}
      </Formik>
      <ConfirmDialog
        open={deleteConfirmOpen.get()}
        onClose={toggleDeleteConfirmDialog}
        onConfirm={handleDeleteCertificateTemplate}
        title={_t('confirm.title', 'Do you want to delete this template?')}
        confirmLabel={_t('confirm.delete', 'Delete')}
        confirmButtonColor="error"
        confirmButtonStartIcon={<DeleteRoundedIcon />}
      />
    </BaseDialog>
  );
};

export default CompanyQrSceneCertificateTemplateDialog;

const getValidationSchema = (t: TFunction) =>
  Yup.object({
    sendCcTo: Yup.string().email(
      t('certificateDialog.form.email.valid', 'Invalid e-mail'),
    ),
    attachment: Yup.mixed()
      .test(
        'fileSize',
        t(
          'certificateDialog.form.attachment.fileTooLarge',
          'File is too large. Maximum is 10 MB',
        ),
        (value) => {
          if (!value) {
            return true;
          }
          return value.size <= MAX_UPLOAD_FILE_SIZE;
        },
      )
      .test(
        'fileType',
        t(
          'certificateDialog.form.attachment.fileTypeForbidden',
          'This file type is not supported.',
        ),
        (value) => {
          if (!value) {
            return true;
          }
          return SUPPORTED_FORMATS.includes(value?.type);
        },
      ),
  });
