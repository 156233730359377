import { getOperationName } from '@apollo/client/utilities';
import { Button } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  CreateUserGroupDocument,
  EditUserGroupDocument,
  GetUserGroupsDocument,
  UserGroupFragmentFragment,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';

interface Props {
  open: boolean;
  companyId: string;
  userGroup?: UserGroupFragmentFragment;
  onClose: () => void;
}

interface FormData {
  name: string;
}

const CompanyUserGroupDialog = ({
  open,
  userGroup,
  onClose,
  companyId,
}: Props) => {
  const { _t, t } = useTranslationPrefix('CompanyUserGroupDialog');
  const [createMutation] = useMutationWithSnackbar(
    'CompanyUserGroupDialog.createMutation',
    CreateUserGroupDocument,
    {
      refetchQueries: [getOperationName(GetUserGroupsDocument)!],
    },
  );
  const [editMutation] = useMutationWithSnackbar(
    'CompanyUserGroupDialog.editMutation',
    EditUserGroupDocument,
  );

  const handleSubmit = async (values: FormData) => {
    if (userGroup) {
      await editMutation({
        variables: {
          input: {
            name: values.name,
            id: userGroup.id,
          },
        },
      });
    } else {
      await createMutation({
        variables: {
          input: {
            companyId: companyId,
            name: values.name,
          },
        },
      });
    }
    onClose();
  };
  return (
    <BaseDialog
      title={
        userGroup
          ? _t('editTitle', 'Edit user group')
          : _t('newTitle', 'Create user group')
      }
      open={open}
      onClose={onClose}
    >
      {open && (
        <Formik<FormData>
          initialValues={{
            name: userGroup?.name || '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema(_t)}
        >
          {({ isSubmitting, isValid }) => (
            <FormikForm>
              <FormikTextField
                name="name"
                variant="outlined"
                label={_t('name', 'Name')}
              />

              <Flex justifyContent="space-between" pt={2}>
                <Button
                  id="edit-company-user-dialog-cancel"
                  size="large"
                  color="primary"
                  onClick={onClose}
                >
                  {t('common.cancel', 'Cancel')}
                </Button>
                <Button
                  id="edit-company-user-dialog-confirm"
                  type="submit"
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {_t('editCompanyDialog.save', 'Save')}
                </Button>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      )}
    </BaseDialog>
  );
};

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    name: Yup.string()
      .min(3, _t('form.nameMin', 'Minimal number of characters is 3'))
      .required(_t('form.nameRequired', 'Name is required')),
  });

export default CompanyUserGroupDialog;
