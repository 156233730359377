import { useTranslation } from 'react-i18next';
import { CompanyStatus } from '../../../graphql/graphql-operations';
import { TopBar } from '../../components/TopBar';
import Actions from './components/Actions';
import CompanyList from './components/CompanyList';

export interface TableData {
  id: string;
  name: string;
  note: string;
  streamType: String;
}

interface Props {
  companyStatus: CompanyStatus;
}

const CompaniesPage = ({ companyStatus }: Props) => {
  const { t } = useTranslation();

  const companyStatusLowerCase = companyStatus.toLowerCase();
  return (
    <>
      <TopBar
        leftSideText={t(
          `companies.${companyStatusLowerCase}.topBar.title`,
          'Clients',
        )}
        actions={<Actions companyStatus={companyStatus} />}
      />
      <CompanyList
        companyStatus={companyStatus}
        translationPrefix="companyList.companiesTableControlBar"
      />
    </>
  );
};

export default CompaniesPage;
