import { useQuery } from '@apollo/client';
import { Button, MenuItem } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { BaseDialog } from '../../../../base/components/BaseDialog';
import { Flex } from '../../../../base/components/Flex';
import FormikForm from '../../../../base/components/form/FormikForm';
import FormikSelect from '../../../../base/components/form/FormikSelect';
import FormikTextField from '../../../../base/components/form/FormikTextField';
import {
  CompanyApplicationScenesDocument,
  CreateCompanyQrSceneDocument,
} from '../../../../graphql/graphql-operations';
import { useMutationWithSnackbar } from '../../../../hooks/useMutationWithSnackbar';
import {
  TFunctionPrefixed,
  useTranslationPrefix,
} from '../../../../hooks/useTranslationPrefix';

interface FormData {
  sceneId: string;
  additionalParams: string;
  ageFrom: string;
  ageTo: string;
}

interface Props {
  companyId: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

const CreateCompanyQrSceneDialog = ({
  open,
  companyId,
  onClose,
  onSuccess,
}: Props) => {
  const { _t } = useTranslationPrefix('CreateCompanyQrSceneDialog');

  const { data: companyAppsData } = useQuery(CompanyApplicationScenesDocument, {
    variables: {
      companyIdInput: {
        companyId,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const scenes = companyAppsData?.companyApplicationScenes || [];

  const [createMutation] = useMutationWithSnackbar(
    'CreateCompanyQrSceneDialog.createMutation',
    CreateCompanyQrSceneDocument,
    {
      onCompleted: () => {
        onSuccess?.();
      },
    },
  );

  const handleSubmit = async (
    values: FormData,
    helpers: FormikHelpers<FormData>,
  ) => {
    await createMutation({
      variables: {
        input: {
          companyId: companyId,
          ageFrom: parseInt(values.ageFrom),
          ageTo: parseInt(values.ageTo),
          sceneId: values.sceneId,
          additionalParams: values.additionalParams,
        },
      },
    });
  };

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={_t('title', 'Add new QR app')}
      disableEnforceFocus
    >
      <>
        <Formik<FormData>
          initialValues={{
            sceneId: '',
            additionalParams: '{"lang": "cs-CZ"}',
            ageFrom: '0',
            ageTo: '100',
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={getValidationSchema(_t)}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              <Flex flexDirection="column" gap={3}>
                <FormikSelect
                  fullWidth
                  name="sceneId"
                  label={_t('sceneId', 'Scene')}
                >
                  {scenes?.map((scene) => (
                    <MenuItem key={scene.id} value={scene.id}>
                      {scene.name_t}
                    </MenuItem>
                  ))}
                </FormikSelect>
                <FormikTextField
                  fullWidth
                  name="ageFrom"
                  label={_t('ageFrom', 'Age from')}
                />
                <FormikTextField
                  fullWidth
                  name="ageTo"
                  label={_t('ageTo', 'Age to')}
                />
                <FormikTextField
                  fullWidth
                  name="additionalParams"
                  label={_t(
                    'dialog.additionalParams',
                    'Additional params (json)',
                  )}
                  multiline
                  minRows={3}
                  maxRows={8}
                />

                <Flex
                  justifyContent="center"
                  mt={1}
                  gap={3}
                  flexDirection={{ xs: 'column-reverse', md: 'row' }}
                >
                  <Button
                    id="create-playlist-folder-cancel"
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.cancel', 'Cancel')}
                  </Button>
                  <Button
                    id="create-playlist-folder-confirm"
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {_t('dialog.submit', 'Save')}
                  </Button>
                </Flex>
              </Flex>
            </FormikForm>
          )}
        </Formik>
      </>
    </BaseDialog>
  );
};

export default CreateCompanyQrSceneDialog;

const getValidationSchema = (_t: TFunctionPrefixed) =>
  Yup.object({
    sceneId: Yup.string().required(_t('sceneIdRequired', 'Select Scene')),
    additionalParams: Yup.string()
      .required(
        _t('additionalParamsRequired', 'Additional params are required'),
      )
      .test('json', _t('additionalParamsInvalid', 'Invalid json'), (value) => {
        try {
          JSON.parse(value || '');
          return true;
        } catch (error) {
          return false;
        }
      }),
    ageFrom: Yup.number()
      .typeError(_t('ageFromRequired', 'Age from is required'))
      .required(_t('ageFromRequired', 'Age from is required'))
      .min(0, _t('ageFromMin', 'Age from cannot be negative'))
      .max(100, _t('ageFromRequired', 'Age from is too high')),
    ageTo: Yup.number()
      .typeError(_t('ageToRequired', 'Age to is required'))
      .required(_t('ageToRequired', 'Age to is required'))
      .min(0, _t('ageToMin', 'Age to cannot be negative'))
      .max(100, _t('ageToRequired', 'Age to is too high')),
  });
