import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { CardContent, IconButton, Link, Typography } from '@mui/material';
import * as QRCode from 'qrcode';
import { useEffect, useMemo } from 'react';
import { BaseCard } from '../../../../base/components/BaseCard';
import { Flex } from '../../../../base/components/Flex';
import { CompanyQrsQuery } from '../../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { formatDateTime } from '../../../../lib/date';

interface Props {
  qrCode: CompanyQrsQuery['companyQrs'][0];
  onInvalidateClick: () => void;
  onEditClick: () => void;
}

const prepareUrl = (qrId: string) => {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  let port = window.location.port;
  port = port ? `:${port}` : '';

  return `${protocol}//${hostname}${port}/qr/${qrId}`;
};

const CompanyQrCard = ({ qrCode, onInvalidateClick, onEditClick }: Props) => {
  const { _t } = useTranslationPrefix('CompanyQrsPage');
  const url = useMemo(() => prepareUrl(qrCode.id), [qrCode.id]);

  useEffect(() => {
    QRCode.toCanvas(document.getElementById(`qr-code-${qrCode.id}`), url, {
      width: 200,
      scale: 1,
    });
    QRCode.toDataURL(url, {
      width: 200,
      scale: 1,
    }).then((url) => {
      document
        .getElementById(`qr-code-${qrCode.id}-download`)
        ?.setAttribute('href', url);
    });
  }, [url, qrCode.id]);

  return (
    <BaseCard>
      <CardContent>
        <Flex alignItems="flex-start">
          <Flex flexDirection="column" alignItems="center">
            <canvas id={`qr-code-${qrCode.id}`}></canvas>
            <a
              id={`qr-code-${qrCode.id}-download`}
              href="_"
              download={`qr-code-${qrCode.id}.png`}
              style={{ display: 'flex', gap: '1ch' }}
            >
              <DownloadRoundedIcon sx={{ fontSize: '1.5rem' }} />
              <span>{_t('download', 'Download')}</span>
            </a>
          </Flex>

          <Flex
            display="grid"
            gridTemplateColumns="repeat(2, auto)"
            gap={1}
            p={2}
          >
            <Typography color="grey.500">{_t('link', 'Link:')}</Typography>
            <Link target="_blank" href={url}>
              {url}
            </Link>

            <Typography color="grey.500">
              {_t('company', 'Company:')}
            </Typography>
            <Typography>{qrCode.company.name}</Typography>

            <Typography color="grey.500">
              {_t('vrDevice', 'VR Device:')}
            </Typography>
            <Typography>{qrCode.vrDevice.name}</Typography>

            {qrCode.validTo && (
              <>
                <Typography color="grey.500">
                  {_t('validTo', 'Valid to:')}
                </Typography>
                <Typography>{formatDateTime(qrCode.validTo)}</Typography>
              </>
            )}
            <Typography color="grey.500">
              {_t('additionalParams', 'Additional params:')}
            </Typography>
            <Typography component="pre">
              {JSON.stringify(JSON.parse(qrCode.additionalParams), null, 4)}
            </Typography>
          </Flex>
          <Flex flexGrow={1} justifyContent="flex-end" gap={1}>
            <IconButton
              id={`edit-qr-${qrCode.id}`}
              onClick={onEditClick}
              color="info"
            >
              <EditRoundedIcon />
            </IconButton>

            <IconButton
              id={`invalidate-qr-${qrCode.id}`}
              onClick={onInvalidateClick}
              color="error"
            >
              <DeleteRoundedIcon />
            </IconButton>
          </Flex>
        </Flex>
      </CardContent>
    </BaseCard>
  );
};

export default CompanyQrCard;
