import { useQuery } from '@apollo/client';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmDialog from '../../../base/components/ConfirmDialog/ConfirmDialog';
import { Flex } from '../../../base/components/Flex';
import { Loader } from '../../../client/components/Loader';
import QrSceneCard from '../../../client/pages/LaunchCompanyQrPage/components/QrSceneCard';
import {
  CompanyQrScenesDocument,
  CompanyQrScenesQuery,
  InvalidateCompanyQrSceneDocument,
} from '../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { TopBar } from '../../components/TopBar';
import CreateCompanyQrSceneDialog from './components/CreateCompanyQrSceneDialog';
import EditCompanyQrSceneDialog from './components/EditCompanyQrSceneDialog';

import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMutationWithSnackbar } from '../../../hooks/useMutationWithSnackbar';
import CompanyQrSceneCertificateTemplateDialog from './components/CompanyQrSceneCertificateTemplateDialog';

export type CompanyQrScene = CompanyQrScenesQuery['companyQrScenes'][0];

const CompanyQRScenesPage = () => {
  const { _t } = useTranslationPrefix('CompanyQRScenesPage');
  const { companyId } = useParams<{ companyId: string }>();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState<CompanyQrScene | null>(
    null,
  );
  const [certDialogOpen, setCertDialogOpen] = useState<CompanyQrScene | null>(
    null,
  );
  const [invalidateConfirmOpen, setInvalidateConfirmOpen] =
    useState<CompanyQrScene | null>(null);

  const { data, refetch, error, loading } = useQuery(CompanyQrScenesDocument, {
    variables: {
      input: {
        companyId: companyId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  useQueryErrorSnackbar(error, refetch);

  const [invalidate] = useMutationWithSnackbar(
    'CompanyQRScenesPage.invalidateMutation',
    InvalidateCompanyQrSceneDocument,
    {
      onCompleted: () => {
        refetch();
        setInvalidateConfirmOpen(null);
      },
    },
  );

  const handleInvalidateConfirm = async () => {
    if (invalidateConfirmOpen) {
      await invalidate({
        variables: {
          input: {
            id: invalidateConfirmOpen.id,
          },
        },
      });
    }
  };

  return loading || !data?.companyQrScenes ? (
    <Loader />
  ) : (
    <>
      <TopBar
        leftSideText={_t('title', 'QR apps')}
        actions={
          <Button
            id="create-qr-scene-dialog"
            variant="contained"
            onClick={() => setCreateDialogOpen(true)}
            startIcon={<AddRoundedIcon />}
          >
            {_t('createQrScene', 'Add QR Scene')}
          </Button>
        }
      />

      <Flex gap={2} flexDirection="column">
        {data?.companyQrScenes?.map((s) => (
          <Card key={s.id}>
            <CardContent>
              <Flex>
                <Box sx={{ width: '25rem' }}>
                  <QrSceneCard scene={s.scene} />
                </Box>
                <Flex
                  display="grid"
                  gridTemplateColumns="repeat(2, auto)"
                  gap={1}
                  p={2}
                >
                  <Typography color="grey.500">
                    {_t('ageFrom', 'Age from:')}
                  </Typography>
                  <Typography>{s.ageFrom}</Typography>
                  <Typography color="grey.500">
                    {_t('ageTo', 'Age to:')}
                  </Typography>
                  <Typography>{s.ageTo}</Typography>

                  {s.certFileLink && (
                    <>
                      <Typography color="grey.500">
                        {_t('certificate', 'Certificate:')}
                      </Typography>
                      <a
                        id={`qr-app-cert-${s.id}-download`}
                        href={s.certFileLink}
                        download={true}
                        style={{ display: 'flex' }}
                      >
                        <DownloadRoundedIcon sx={{ fontSize: '1.5rem' }} />
                        <span>{_t('download', 'Download')}</span>
                      </a>

                      <Typography color="grey.500">
                        {_t('certSendCcTo', 'Send copy to:')}
                      </Typography>
                      <Typography>{s.certSendCcTo}</Typography>
                      <Typography color="grey.500">
                        {_t('certSendToTrainees', 'Send to trainees:')}
                      </Typography>
                      <Typography>
                        {s.certSendToTrainees ? `✔️` : `❌`}
                      </Typography>
                    </>
                  )}

                  <Typography color="grey.500">
                    {_t('additionalParams', 'Additional params:')}
                  </Typography>
                  <Typography component="pre">
                    {JSON.stringify(JSON.parse(s.additionalParams), null, 4)}
                  </Typography>
                </Flex>
                <Flex flexGrow={1} flexDirection="column" alignItems="end">
                  <Flex
                    flexGrow={1}
                    justifyContent="flex-end"
                    alignItems="start"
                    gap={1}
                  >
                    <IconButton
                      id={`edit-qr-scene-${s.id}`}
                      onClick={() => setEditDialogOpen(s)}
                      color="info"
                    >
                      <EditRoundedIcon />
                    </IconButton>

                    <IconButton
                      id={`invalidate-qr-scene-${s.id}`}
                      onClick={() => setInvalidateConfirmOpen(s)}
                      color="error"
                    >
                      <DeleteRoundedIcon />
                    </IconButton>
                  </Flex>

                  <Button
                    id={`manage-qr-scene-certificate-${s.id}`}
                    variant="outlined"
                    onClick={() => setCertDialogOpen(s)}
                  >
                    {_t('manageCertificate', 'Manage certificate')}
                  </Button>
                </Flex>
              </Flex>
            </CardContent>
          </Card>
        ))}
      </Flex>

      <CreateCompanyQrSceneDialog
        open={createDialogOpen}
        companyId={companyId!}
        onClose={() => setCreateDialogOpen(false)}
        onSuccess={() => {
          refetch();
          setCreateDialogOpen(false);
        }}
      />
      {editDialogOpen && (
        <EditCompanyQrSceneDialog
          scene={editDialogOpen}
          onClose={() => setEditDialogOpen(null)}
          onSuccess={() => {
            refetch();
            setEditDialogOpen(null);
          }}
        />
      )}

      {certDialogOpen && (
        <CompanyQrSceneCertificateTemplateDialog
          qrScene={certDialogOpen}
          onClose={() => setCertDialogOpen(null)}
          onSuccess={() => {
            refetch();
            setCertDialogOpen(null);
          }}
        />
      )}

      <ConfirmDialog
        title={_t('invalidateConfirm', 'Invalidate QR app?')}
        open={!!invalidateConfirmOpen}
        onClose={() => setInvalidateConfirmOpen(null)}
        onConfirm={handleInvalidateConfirm}
        confirmButtonColor="error"
        confirmLabel={_t('invalidate', 'Invalidate')}
        confirmButtonStartIcon={<DeleteForeverIcon />}
      />
    </>
  );
};

export default CompanyQRScenesPage;
