import { useQuery } from '@apollo/client';
import { TableCellLink } from '../../../../base/components/BaseTable';
import {
  ColumnConfig,
  SimpleTable,
} from '../../../../base/components/SimpleTable';
import {
  CompanyStatus,
  GetDirectSubsidiariesDocument,
} from '../../../../graphql/graphql-operations';
import useQueryErrorSnackbar from '../../../../hooks/useQueryErrorSnackbar';
import { useTranslationPrefix } from '../../../../hooks/useTranslationPrefix';
import { SectionPaper } from '../../../components/SectionPaper';
import CompanyRowActions from './CompanyRowActions';

interface Props {
  translationPrefix: string;
  companyId?: string | null;
  companyStatus: CompanyStatus;
  className?: string;
}

export interface TableData {
  id: string;
  name: string;
  note: string;
  streamType: String;
}

const CompanyList = ({
  companyId,
  companyStatus,
  className,
  translationPrefix,
}: Props) => {
  const { t } = useTranslationPrefix('companyList');
  const { data, loading, error, refetch } = useQuery(
    GetDirectSubsidiariesDocument,
    {
      variables: {
        subsidiaryInput: {
          parentCompanyId: companyId ?? null,
          filter: {
            status: companyStatus,
          },
        },
      },
    },
  );
  useQueryErrorSnackbar(error, refetch);
  const companies = data?.directSubsidiaries || [];

  const tableData: TableData[] = companies.map((company) => ({
    id: company.id,
    name: company.name,
    note: company.note || '',
    streamType: t(`enum.streamType.${company.streamType}`),
  }));

  const columns: ColumnConfig<TableData>[] = [
    {
      id: 'name' as const,
      defaultTrans: 'Name',
      component: ({ name, id }) => (
        <TableCellLink to={`/admin/companies/${id}`} label={name} />
      ),
    },
    {
      id: 'streamType' as const,
      defaultTrans: 'Type',
    },
    {
      id: 'note' as const,
      defaultTrans: 'Note',
    },
  ];
  const companyStatusLowerCase = companyStatus.toLowerCase();

  return (
    <SectionPaper className={className}>
      <SimpleTable<TableData>
        id="companies"
        rowId="id"
        isLoading={loading}
        translationPrefix={`${translationPrefix}.${companyStatusLowerCase}`}
        data={tableData}
        columns={columns}
        renderRowActions={(row) => (
          <CompanyRowActions companyStatus={companyStatus} rowData={row} />
        )}
        searchConfig={{
          defaultPlaceholder: 'Search {{count}} clients',
          filterBy: 'name',
          defaultTitle: 'Clients list',
        }}
      />
    </SectionPaper>
  );
};

export default CompanyList;
