import { useState } from '@hookstate/core';
import MessageIcon from '@mui/icons-material/Message';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../../../base/components/ConfirmDialog/ConfirmDialog';
import { CompanyStatus } from '../../../../graphql/graphql-operations';
import { TableData } from '../CompaniesPage';
import { useCompanyStatusMutation } from '../hooks';
import EditCompanyNoteDialog from './EditCompanyNoteDialog';
interface Props {
  rowData: TableData;
  companyStatus: CompanyStatus;
}

const CompanyRowActions: React.VFC<Props> = (props: Props) => {
  const { companyStatus, rowData } = props;
  const { id: companyId, note } = rowData;
  const { t } = useTranslation();
  const state = useState({
    editCompanyOpen: false,
    statusChangeOpen: false,
  });

  const { companyStatusMutation } = useCompanyStatusMutation({
    onCompleted: () => state.statusChangeOpen.set(false),
  });

  const unarchiveCompany = () => {
    companyStatusMutation({
      variables: {
        status: {
          companyId: companyId,
          newStatus: CompanyStatus.ACTIVE,
        },
      },
    });
  };

  const openStatusChangeDialog = () => {
    state.statusChangeOpen.set(true);
  };

  const closeStatusChangeDialog = () => {
    state.statusChangeOpen.set(false);
  };

  return (
    <>
      {CompanyStatus.ACTIVE === companyStatus ? (
        <>
          <Tooltip title={<>{t('companies.rowActions.editNote')}</>}>
            <IconButton
              id={`company-row-action-edit-${companyId}`}
              color="primary"
              onClick={() => state.editCompanyOpen.set(true)}
              size="large"
            >
              <MessageIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={<>{t('companies.rowActions.unarchive')}</>}>
            <IconButton
              id={`company-row-action-reactivate-${companyId}`}
              color="primary"
              onClick={openStatusChangeDialog}
              size="large"
            >
              <UnarchiveIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <EditCompanyNoteDialog
        open={state.editCompanyOpen.get()}
        onClose={() => state.editCompanyOpen.set(false)}
        currentNote={note}
        companyId={companyId}
      />

      <ConfirmDialog
        title={t('companies.rowActions.unarchiveConfirm', 'Unarchive company?')}
        open={state.statusChangeOpen.get()}
        onClose={closeStatusChangeDialog}
        onConfirm={unarchiveCompany}
      />
    </>
  );
};

export default CompanyRowActions;
