import { ApolloProvider } from '@apollo/client';
import { CssBaseline, styled, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorPage from './admin/components/ErrorPage/ErrorPage';
import PrivateRoute from './admin/components/PrivateRoute/PrivateRoute';
import { VR_BOX_PARAM } from './admin/pages/Companies/VrBoxes/VrBoxList';
import adminTheme from './admin/theme';
import AppContent from './AppContent';
import { setVrBoxIdToLocalStorage } from './base/components/VrDevicesProvider/VrDevicesProvider';
import { Login } from './base/pages/Login';
import LoginPasswordReset from './base/pages/Login/LoginPasswordReset';
import HelpFaqRoute from './client/pages/HelpFaq/HelpFaqRoute';
import LaunchCompanyQrPage from './client/pages/LaunchCompanyQrPage/LaunchCompanyQrPage';
import { LiveVrSession } from './client/pages/LiveVrSession';
import { lightTheme } from './client/theme';
import createApolloClient from './graphql/client';
import { initializeI18n } from './i18n/i18n';
import { ClientLayout } from './layouts';
import { useAppVariant } from './lib/useAppVariant';

const client = createApolloClient();

initializeI18n();

function App() {
  const { isAdminUrl } = useAppVariant();
  const vrBoxId = new URLSearchParams(window.location.search).get(VR_BOX_PARAM);

  useEffect(() => {
    if (vrBoxId) {
      setVrBoxIdToLocalStorage(vrBoxId);
    }
  }, [vrBoxId]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={isAdminUrl ? adminTheme : lightTheme}>
        <CssBaseline />
        <StyledSnackbarProvider maxSnack={3}>
          <Sentry.ErrorBoundary
            fallback={({ eventId }) => <ErrorPage eventId={eventId} />}
          >
            <Suspense fallback={<div />}>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/live-vr-session/:vrSessionId"
                    element={<LiveVrSession />}
                  />
                  <Route path="/qr/:id" element={<LaunchCompanyQrPage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/login/reset" element={<LoginPasswordReset />} />
                  <Route
                    path="/help-login/*"
                    element={
                      <ClientLayout>
                        <HelpFaqRoute />
                      </ClientLayout>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <AppContent />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </BrowserRouter>
            </Suspense>
          </Sentry.ErrorBoundary>
        </StyledSnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;

const StyledSnackbarProvider = styled(SnackbarProvider)(
  ({ theme: { palette } }) => ({
    '&.SnackbarItem-variantSuccess': {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText,
    },
    '&.SnackbarItem-variantError': {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText,
    },
    '&.SnackbarItem-variantInfo': {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
    },
  }),
);
