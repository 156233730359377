import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router';
import HasCompanyManagementPermission from '../../../base/components/UserProvider/HasCompanyManagementPermission';
import { GetCompanyDocument } from '../../../graphql/graphql-operations';
import { useTranslationPrefix } from '../../../hooks/useTranslationPrefix';
import { TopBar } from '../../components/TopBar';
import ApplicationSceneList from './components/ApplicationSceneList';

const CompanyScenesPage = () => {
  const { _t } = useTranslationPrefix('CompanyScenesPage');
  const { companyId } = useParams<{ companyId: string }>();
  const { data, loading } = useQuery(GetCompanyDocument, {
    variables: {
      companyIdInput: {
        companyId: companyId,
      },
    },
    skip: !companyId,
  });

  const company = data?.company;
  if (loading || !company) return <CircularProgress />;
  return (
    <>
      <TopBar
        leftSideText={_t('title', 'Apps of {{companyName}}', {
          companyName: company.name,
        })}
      />
      <HasCompanyManagementPermission companyId={companyId!}>
        <ApplicationSceneList companyId={companyId!} />
      </HasCompanyManagementPermission>
    </>
  );
};

export default CompanyScenesPage;
